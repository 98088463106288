
import { ROOT_ACTIONS } from '@/store/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppBar extends Vue {
  get sidebar(): boolean {
    return this.$store.state.navigation;
  }
  set sidebar(value: boolean) {
    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NAVIGATION, value)
  }
}
